<template>
  <div>
    <div class="iframe-wrapper" style="margin: 0 auto; width: 735px; max-width: 100%; padding-top: 54%; position: relative">
      <iframe v-if="agreement" :src="`${agreement}#toolbar=0`" frameborder="0" style="position: absolute; top: 0; left: 0; margin: 0 5%; width: 90%; height: 100%; border: 0;" />
      <iframe v-else src="/" frameborder="0" style="position: absolute; top: 0; left: 0; margin: 0 5%; width: 90%; height: 100%; border: 0;" />
    </div>
    <div class="width-100 my-3 mx-auto">
      <a-list-item-meta>
        <template #title>
          <a :href="agreement" target="_blank">Скачать <br/>PDF</a>
        </template>
        <template #avatar>
          <a-avatar :size="50" shape="square" style="background-color: #ffffff">
            <template #icon><FileTextTwoTone /></template>
          </a-avatar>
        </template>
      </a-list-item-meta>
      <a-button
        v-if="!signed"
        @click="signModal = true"
        type="primary"
        class="mt-3"
      >Подписать</a-button>
    </div>
    <a-modal
      :width="300"
      v-model:visible="signModal"
      title="Выберите сертификат"
      ok-text="Подписать"
      cancel-text="Закрыть"
      @ok="sign"
      @cancel="signModal = false"
      :confirm-loading="signLoading"
    >
      <a-select
        @change="chooseCertificate"
        placeholder="Выберите сертификат"
        label-in-value
        style="width: 100%"
      >
        <a-select-option :disabled="cert.validTo < new Date() || ![cert['TIN'], cert['PINFL']].includes(tin)" v-for="cert in certificates" :key="cert.name" :value="cert.name">
          {{ getCertificateLabel(cert) }}
        </a-select-option>
      </a-select>
    </a-modal>
  </div>
</template>
<script>
import apiClient from "@/services/axios";
import {FileTextTwoTone} from "@ant-design/icons-vue";
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import EIMZO from "@/helpers/e-imzo";
import {notification} from "ant-design-vue";

export default {
  name: 'VbRegister',
  components: {
    FileTextTwoTone
  },
  setup() {
    const certificate = ref()
    const certificates = ref([])
    const signed = ref(false)
    const tin = ref(null)
    const signModal = ref(false)
    const signLoading = ref(false)
    const route = useRoute()
    const agreement = ref(false)
    onMounted(async () => {
      try {
        const eimzo = new EIMZO
        await eimzo.install()
        certificates.value = await eimzo.listAllUserKeys()
      } catch (e) {
        // console.error(e)
      }
      apiClient.get(`/auth/agreement?user=${route.params.user}&account=${route.params.account}`)
        .then(({data}) => {
          agreement.value = data.data.agreement
          tin.value = data.data.tin
          if (data.data.signed) {
            signed.value = true
          }
        })
        .catch(() => {
          // console.log(err)
        })
        .finally(() => {})
    })
    const getCertificateLabel = (cert) => {
      if (cert['O']) {
        return `${cert['O']} (${cert['TIN']})`
      }
      return `${cert['CN']} (${cert['PINFL']}`
    }
    const chooseCertificate = (cert) => {
      certificate.value = cert.key
    }
    const sign = async () => {
      if (!certificate.value)
        return;
      signLoading.value = true
      try {
        let id = ''
        const eimzo = new EIMZO
        const selectedCertificate = await certificates.value.find(cert => cert.name === certificate.value)
        await eimzo.loadKey(selectedCertificate).then(key => id = key.id)
        const result = await eimzo.signPkcs7(selectedCertificate, id)
        apiClient.post('auth/eimzo-timestamp', {base64: result['pkcs7_64']}).then((res) => {
          apiClient.post('auth/eimzo-verify', {base64: res.data.data['pkcs7b64'], id: route.params.user}).then((res) => {
            if (res.data.data) {
              signed.value = true
              signModal.value = false
              notification.success({
                message: 'Соглашение подписано успешно'
              })
            }
          }).catch(() => {
            signModal.value = false
          }).finally(() => {
            signLoading.value = false
          })
        }).catch(() => {
          signModal.value = false
        }).finally(() => {
          signLoading.value = false
        })
        signLoading.value = false
      } catch (e) {
        // console.log(e, 'try catch')
        signLoading.value = false
      }
    }
    return {
      tin,
      signed,
      agreement,
      signModal,
      signLoading,
      certificate,
      certificates,
      sign,
      chooseCertificate,
      getCertificateLabel,
    }
  },
}
</script>
<style lang="scss" module>
@import '@/@vb/components/Auth/style.module.scss';
</style>
<style lang="css">
@media (max-width: 1440px) {
  .iframe-wrapper {
    padding-top: 65% !important;
  }
}
@media (max-width: 1024px) {
  .iframe-wrapper {
    padding-top: 91% !important;
  }
}
@media (max-width: 665px) {
  .iframe-wrapper {
    padding-top: 127% !important;
  }
}
</style>
